import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Music from './components/Music';
import Shows from './components/Shows';
import Videos from './components/Videos';
import Header from './components/Header';
import AlertModal from './components/AlertModal';
import moment from 'moment';
import About from './components/About';



function App() {
  const [buttonClicked, setButtonClicked] = useState({music: true});
  const handleButtonClicked = ({target}) => {
    setButtonClicked({[target.id]: true});
  };
  const [smallScreen] = useState(window.innerWidth < 480);
  const releaseTime = moment().isBefore('2019-10-24 20:00', 'YYYY-MM-DD HH:mm');
  const [siteData, setSiteData] = useState({videos: []})
  const getStorageData = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.log('error', error);
      return [];
    }
  }

  
  useEffect(() => {
    const getVideoList = async () => {
      const videoList = await getStorageData('/getStorageData');
      setSiteData(s => ({...s, videos: videoList}));
    }
    getVideoList();
  }, []);

  return (
    <div className="background">
      <Header
        buttonClicked={buttonClicked}
        handleButtonClicked={handleButtonClicked}
      />
      {releaseTime && <AlertModal />}
      <div className="custom-container">
        { buttonClicked.music && <Music smallScreen={smallScreen} /> }
        { buttonClicked.videos && <Videos videoList={siteData.videos} /> }
        { buttonClicked.about && <About /> }
        { buttonClicked.shows && <Shows /> }
        <a className='footer-container' href='https://www.instagram.com/theflyrebel/' target="_blank" rel="noopener noreferrer">
          <img className='ig-logo' src='./assets/ig_logo.svg' alt='instagram logo' style={{marginBottom: '15px'}} />
        </a>
      </div>
    </div>
  );
}

export default App;

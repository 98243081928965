import React from 'react';
import {MENU_BUTTONS} from '../constants/en_us.json'
import Button from './Button';

const MenuButtons = ({buttonClicked, handleButtonClicked}) => (
  <div className='menu-buttons-group'>
    {
      MENU_BUTTONS.map( ({id, name}) => {
        if (id !== 'shows'){
          return (
            <Button
                key={id}
                id={id}
                className={buttonClicked[id] ? 'menu-button-selected' : 'menu-button'}
                handleClick={handleButtonClicked}
                >
              {name}
            </Button>
          )
        }
        return null
      })
    }
  </div>
);

export default MenuButtons;
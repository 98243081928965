import React from 'react';

const Shows = () => {
  return (
    <div className='show'>
      <a href={'https://www.aisle5atl.com/e/new-energy-concert-74247770079/'} target="_blank" rel="noopener noreferrer">
        <div className='title-text' style={{textAlign: 'left'}}>
        <p style={{marginBottom: '10px', textAlign: 'center', fontSize: '25px'}}>$5 OFF WITH CODE "<b>reaz87</b>"</p>
        <img className='show-img' src={`./assets/mmmh_new_energy.jpg`} alt={'Music Makes Me High New Energy Concert'} />
          <p className='show-text' style={{fontWeight: 'bold'}}>Wed, Oct 30, 2019</p>
          <p style={{fontStyle: 'italic'}}>Music Makes Me High: New Energy Concert</p>
          <p className='show-text'>Aisle 5, Atlanta, GA</p>
        </div>
      </a>
    </div>
  )
}

export default Shows;
import React from 'react';
import StreamingIcon from './StreamingIcon';
import {albums} from '../constants/icons.json';

const Music = ({smallScreen}) => (
  <div>
    <h1>NEW RELEASE</h1>
      {/* <div className="subtitle-container">
        <p>BULLS**T <br/> by</p>
        <a href="https://www.instagram.com/theflyrebel/" target="_blank" rel="noopener noreferrer">@TheFlyRebel</a>
      </div> */}
    <div className="title-text">
      <p>Time and Money EP</p>
      <p>Available Now</p>
    </div>
    <div className="music-links-container">
      {
        albums.TimeAndMoney.map(icon => <StreamingIcon key={icon.fileName} {...icon} />)
      }
    </div>
    <img className={'album-image'} src={`./assets/time_and_money_cover.jpg`} alt={'Time and Money EP Cover'} />
    {/* <iframe
      className='soundcloud'
      width="100%"
      height="450"
      scrolling="yes"
      frameBorder="yes"
      allow="autoplay"
      title='Time and Money EP'
      src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/891988630&color=%23673ab7&auto_play=false&hide_related=false&show_comments=true&show_user=false&show_reposts=false&show_teaser=true&visual=${smallScreen}&show_playcount=false&download=true`}
    /> */}
  </div>
);

export default Music;
import React from "react";

const About = ({ smallScreen }) => (
  <div className='about-container'>
    <h1 style={{ fontSize: "1.7em" }}>Meet LA The Fly Rebel</h1>
    <img
      className={"about-image"}
      src={`./assets/DEV01060.jpg`}
      alt={"About LA The Fly Rebel 1"}
    />
    <div className="about-text">
      <p style={{ marginBottom: "5px" }}>
        With the Hip-Hop world currently filled with an abundance of standard
        generic trap-stars and autotune enhanced pop-rapsters, the times seem
        ripe for an artist who is bold enough to buck trends and go against the
        norm.
      </p>

      <p style={{ marginBottom: "5px" }}>
        Insert LA The Fly Rebel, hailing from Atlanta, Georgia by way of the
        Bronx, New York. Born Leaveil Armstrong, LA is the epitome of the
        evolution of popular music. Today’s music industry is more wide open and
        undefinable. No longer are artists confined to one box. What was once in
        the sound of the streets, the art of hip-hop has now become the sound of
        popular music. Just turn on any radio station or stream any "Pop"
        station or playlist and you will notice that the rapper and the singer
        are pretty much interchangeable. LA The Fly Rebel is the embodiment of
        this new phenomena which makes the timing of his arrival impeccable.
      </p>
    </div>
    <img
      className={"about-image"}
      src={`./assets/DEV01090.jpg`}
      alt={"About LA The Fly Rebel 2"}
    />
    <div className="about-text">
      <p style={{ marginBottom: "5px" }}>
        The moniker “LA The Fly Rebel” was not chosen lightly. To LA it has a
        deeper meaning to be the epitome of a rebel, going against the grain
        musically as well as aesthetically which is evident by his “fly” sense
        of fashion. The term “Fly” however also means, elevation, of mind,
        spirit and art. LA’s artistry is Influenced by a wide wage *(range or
        wage?)* of stylings from R&B/Soul artists such as the legendary Marvin
        Gaye and Bruno Mars; Hip-Hop Icons like Eminem, Andre 3000, Jay-Z and
        Kanye West; and Pop stars the likes of Maroon 5, U2 and Ed Sheeran. To
        sum it up, LA The Fly Rebel sees music as a vehicle to reach people on
        an emotional level, “I want to use my music to inspire people” says LA
        The Fly Rebel, “People aren't being stimulated with music anymore
        because it's become so routine and predictable. I think it's time I
        change that”.
      </p>

      <p style={{ marginBottom: "5px" }}>
        Somewhat of a late bloomer, LA didn’t learn to play the piano until he
        reached college. It was at Hampton University in Virginia where LA would
        begin to take music seriously as a career. While there, he and two of
        his classmates formed the alternative Hip-Hop group called ReKaSte. LA
        gained much of his musical experience with ReKaSte as they developed a
        modest following releasing music and performing at many local and major
        venues. In 2012 LA decided to embark on a solo career in Atlanta,
        Georgia and create content that was truly unique and authentic to his
        brand and philosophy. When discussing his decision, LA expressed the
        need for originality, “I want to inspire people to be great and to be
        themselves. I feel people are placed in boxes by society and I want them
        to break out of these metaphorical prisons”. This “rebel spirit” is
        truly what will allow him to stand out in a sea of cookie-cutter artists
        that seem to permeate the industry.
      </p>

      <img
        className={"about-image"}
        style={{ float: "left", marginRight: "20px" }}
        src={`./assets/DEV00990.jpg`}
        alt={"About LA The Fly Rebel 2"}
      />

      <p style={{ marginBottom: "5px" }}>
        In late 2019 LA released the EP “Time and Money” depicting the constant
        struggle many of us face with time and money. "No matter who you are
        rich or poor, white or black, you either running out of time or money
        that's a fact", a line quoted from his EP on the song titled "Time and
        Money". The lead single “Bullshit” is an infectious club banger with a
        relatable message. On "Bullshit" LA laments the fact that in today’s
        society hypocrisy and mediocrity seem to be rewarded over substance and
        integrity. "I just got tired of listening to people's bullshit. Like for
        real! I just wanted to make a plain bold statement that I knew everybody
        could relate to."
      </p>
    </div>
    {/* <iframe
      className='soundcloud'
      width="100%"
      height="450"
      scrolling="yes"
      frameBorder="yes"
      allow="autoplay"
      title='Time and Money EP'
      src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/891988630&color=%23673ab7&auto_play=false&hide_related=false&show_comments=true&show_user=false&show_reposts=false&show_teaser=true&visual=${smallScreen}&show_playcount=false&download=true`}
    /> */}
  </div>
);

export default About;

import React, { useState } from "react";
import { Rings } from "react-loader-spinner";

const VideoThumbnail = ({ link, title, embedLink }) => {
  const [isLoading, setLoading] = useState(true);
  return (
    <div className="text-container">
      {isLoading ? (
        <Rings
          height="100"
          width="100"
          color="white"
          radius="20"
          wrapperStyle={{
            justifyContent: "center",
            position: "relative",
            top: "25%",
            left: 0,
            right: 0,
          }}
          wrapperClass=""
          visible={true}
          ariaLabel="rings-loading"
        />
      ) : (
        <div className="post-it-text">
          <a href={link} target="_blank" rel="noopener noreferrer">
            <p style={{ letterSpacing: "2px", fontSize: "18px" }}>{title}</p>
          </a>
        </div>
      )}

      <iframe
        className="youtube"
        style={{ visibility: isLoading ? "hidden" : "visible" }}
        width="500"
        height="281"
        src={embedLink}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

export default VideoThumbnail;

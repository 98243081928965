import React, {Fragment, useState} from 'react';
import { Modal } from 'react-bootstrap';

const AlertModal = () => {
  
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <Fragment>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <h1 style={{fontSize: '2.0rem', marginLeft: 'auto'}}>
            Live Interview on K-100 Radio
          </h1>
        </Modal.Header>
        <Modal.Body>
          <a href="http://k100radio.com" target="_blank" rel="noopener noreferrer">
            <div className='title-text'>
            <h2>Tonight at 7:30pm EST</h2>
              Click here to listen
            </div>
            <img
              src={'./assets/k100_interview_pic.jpg'}
              alt={'K100 LA The Fly Rebel Interview'}
              style={{maxWidth: '100%'}}
            />
          </a>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </Fragment>
  )
};

export default AlertModal;

import React from 'react';
import MenuButtons from './MenuButtons';

const Header = (props) => (
  <div className='header'>
    <div>
    <a href='https://www.instagram.com/theflyrebel/' target="_blank" rel="noopener noreferrer">
      <img className='header-img' src={`./assets/LA_logo.svg`} alt={'LA The Fly Rebel Logo'} />
    </a>
    </div>
    <MenuButtons {...props}/>
  </div>
);

export default Header;